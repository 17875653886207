import styled from 'styled-components'
import { grayscale, orange, white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  position: relative;
  padding-bottom: 50px;

  @media ${device.tablet} {
    padding-top: 1px;
    /* padding-bottom: 256px; */
  }
`

export const Divisor = styled.div`
  display: none;
  border: 1px solid ${grayscale[200]};
  margin-bottom: 21px;

  @media ${device.tablet} {
    display: flex;
  }
`

export const DivisorDashed = styled.div`
  border: 1px dashed ${grayscale[200]};
  margin-bottom: 40px;
  margin-left: 24px;
  margin-right: 24px;
`

export const Back = styled.div`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.base};

  margin-bottom: 24px;
  cursor: pointer;
  width: fit-content;

  > div {
    margin-right: 4px;
    display: flex;
    align-items: center;
  }

  @media ${device.tablet} {
    margin-bottom: 52px;
  }
`

export const Card = styled.a`
  display: block;
  text-decoration: none;
  background: ${white};
  border-radius: 16px;
  margin-bottom: 40px;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 24px;
    transition: all 0.15s ease-in-out;

    &:hover {
      background: ${grayscale[100]};
      text-decoration: none;
    }
  }
`

export const CardTitle = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${orange.base};

  margin-bottom: 8px;
`

export const CardDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;

  margin-bottom: 16.5px;
`

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardBreadCrumb = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};

  margin-bottom: 0;
`
