import React, { useEffect, useState } from 'react'
import { TrackData } from '@interco/track'
import SignalError from 'src/assets/images/signal.png'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import OrangeIcon from 'src/components/Icons'
import useWidth from 'src/hooks/window/useWidth'

import { Section, Divisor, Back, Card, CardTitle, CardDescription, BreadCrumbWrapper, CardBreadCrumb, DivisorDashed } from './style'

import type { ArticlesPropsModel } from '../../pages/types'

// import { View } from '../../index'
import { normalizeString } from 'src/utils/normalizeString'
import axios from 'axios'
import { navigate } from 'gatsby'
import { View } from 'src/types/View'

const WIDTH_MD = 768

type SelectedTopicProps = {
  submitedSearch: string;
  setView: (view: View) => void;
  setSelectedTopic: (data: ArticlesPropsModel) => void;
}

type CloudSearchReturnProps = {
  fields: {
    category_id: string
    category_title: string
    language: string
    preview: string
    title: string
  }
}

export const Search = ({ submitedSearch, setView }: SelectedTopicProps) => {
  const [searchItemList, setSearchItemList] = useState([])
  const windowWidth = useWidth(300)
  const [loading, setLoading]= useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const returnLink = (article: CloudSearchReturnProps) => {
    const parseTitle = normalizeString(article.fields.title)
    const parseCategory = normalizeString(article.fields.category_title)
    const constructUrlLink = `/${parseCategory}/${parseTitle}`

    return constructUrlLink
  }

  const searchCloudsearch = async () => {
    setLoading(true)
    const response = await axios.get(`${process.env.CLOUDSEARCH_FAQ_BABI}?q=${submitedSearch}&fq=(and+contexts%3A%27pf%27+language%3A%27pt%27)`)
  
    const { 
      data: {
        hits: { 
          hit, 
          found
        }
      } 
    } = response

    setSearchItemList(hit)
    setLoading(false)
  }

  useEffect(() => {
    searchCloudsearch()
  }, [submitedSearch])

  useEffect(() => {
    sendDatalayerEvent({
      section: 'dobra_1',
      element_action: 'click button',
      element_name: 'Busca',
      section_name: 'Como podemos ajudar?',
      element_search: searchItemList.length === 0 ? `Sem resultados para ${submitedSearch}` : submitedSearch
    })
  }, [searchItemList])

  return (
    <Section className='container'>
      <div className='row'>
        <div className='col-12'>
          <Divisor />
          <Back onClick={() => {
            navigate('/')
            // setView(View.Landing)
          }}>
            <div>
              <OrangeIcon size='MD' color='#ff7a00' icon='chevron-left' />
            </div>
            Voltar
          </Back>
        </div>
      </div>
      {!loading ? (
        <div className='row'>
          <div className='col-12'>
            {searchItemList.length === 0 && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <img src={SignalError} alt="" style={{ marginBottom: '24px'  }}/>
                <h2 style={{ fontSize: '24px', lineHeight: '30px', color: '#161616', fontWeight: 600, marginBottom: '8px' }}>Sem resultados para "{submitedSearch}"</h2>
                <p style={{ fontSize: '18px', lineHeight: '22px', textAlign: 'center', color: '#6a6c72', fontWeight: 400 }}>Tente novamente com outros termos</p>
              </div>
            )}
            {searchItemList.map((item: CloudSearchReturnProps, index: number) => {
              const { fields } = item
              const topicUrl = returnLink(item)
              
              return (
                <>
                  <Card
                    key={index}
                    onClick={() => TrackData.log({conteudo: fields.title, url: window.location.href, evento: 'click', categoria: fields.category_title})}
                    href={topicUrl}
                  >
                    <CardTitle>{fields.title}</CardTitle>
                    {windowWidth >= WIDTH_MD ? (
                        <CardDescription>{fields.preview}</CardDescription>
                    ) : (
                      <CardDescription>{fields.preview}</CardDescription>
                    )}
                    <BreadCrumbWrapper>
                      <CardBreadCrumb>Central de Relacionamento</CardBreadCrumb>
                      <div>
                        <OrangeIcon size='SM' color='#6A6C72' icon='chevron-right' />
                      </div>
                      <CardBreadCrumb>{fields.category_title}</CardBreadCrumb>
                    </BreadCrumbWrapper>
                  </Card>
                  <DivisorDashed />
                </>
              )
            })}
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-12">
            <h1>Loading</h1>
          </div>
        </div>
      )}
    </Section>
  )
}
